export enum STAT_STATUSES {
  DOWN = 'down',
  UP = 'up'
}

export interface TokenStatistic {
  label: string
  price: number
  rate: number
  status: STAT_STATUSES
}
