































import Vue from 'vue'
import { TokenStatistic, STAT_STATUSES } from '@/models/statistic'
import { DerivativeInfoType } from '@/views/SmartTrading/TradesTerminal/components/DerivativeInfos/types'

export enum DIRECTIONS {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal'
}

export default Vue.extend({
  props: {
    stats: {
      type: Array as () => TokenStatistic[],
      required: true,
      default: []
    },

    direction: {
      type: String as () => DIRECTIONS,
      default: DIRECTIONS.HORIZONTAL
    }
  },

  data(): {
    btcData: number
    ethData: number
  } {
    return {
      btcData: 0,
      ethData: 0
    }
  },

  computed: {
    STAT_STATUSES() {
      return STAT_STATUSES
    },

    DIRECTIONS() {
      return DIRECTIONS
    },

    isVertical(): boolean {
      return this.direction === DIRECTIONS.VERTICAL
    }
  },

  methods: {
    connectSocket() {
      this.$socket.open()

      this.$socket.on('REALTIME_ORDERBOOK', (data: DerivativeInfoType) => {
        if (data.exchange === 'binance') {
          if (data.symbol === 'BTCUSDT') {
            this.getStatData(data)
          }

          if (data.symbol === 'ETHUSDT') {
            this.getStatData(data, 'eth')
          }
        }

        if (!this.btcData || !this.ethData) {
          if (data.exchange === 'kraken') {
            if (data.symbol === 'XXBTZUSD') {
              this.getStatData(data)
            }

            if (data.symbol === 'XETHZUSD') {
              this.getStatData(data, 'eth')
            }
          }
        }
      })
    },

    getStatData(records: DerivativeInfoType, symbol = 'btc') {
      const dataRes = records.asks.map((item: any) => item[0]).sort((a: number, b: number) => a - b)[0]
      if (symbol === 'btc') {
        this.btcData = dataRes
      } else {
        this.ethData = dataRes
      }
    }
  },

  mounted() {
    this.connectSocket()
  },

  beforeDestroy() {
    this.$socket.disconnect()
  }
})
